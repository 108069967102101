import React, { useEffect, useState } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../../components/Layouts/mainPage"
import { NewHeader } from "../../components/Headers/NewHeader"
import { Banner } from "../../components/Banners/BannerHalvaIVklad"
import { FormPKW } from "../../components/FormPKW"
import { NewCalculator } from "../../components/NewCalculator/NewCalculator"
import { MoreBenefitsRedisign } from "../../components/MorBenefitsRedisgn"
import { FourAdvantagesPoints } from "../../components/FourAdvantagesPoints"
import { InstallmentAndDeposit } from "../../components/InstallmentAndDeposit"
import { BottomCta, RefinSeoBgImg } from "../../components/BottomCta"
import { Partners } from "../../components/PartnersNew"
import { Reviews } from "../../components/Reviews"
import { Questions } from "../../components/Questions"
import { NewFooter } from "../../components/NewFooter"

import { getPageData } from "../../helpers/getPageData"

import { PageData } from "../../interfaces/pageProps"
import { QUESTIONS_FOR_HALVA_PKW } from "../../components/Questions/helpers"
import { useHybridBanner } from "../../hooks/useHybridBanner"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)
const bannerTitle = `Покупай в рассрочку`
const bannerDescription = `и зарабатывай на вкладе`

const subTitlePartners = <>Совершайте покупки и&nbsp;участвуйте в&nbsp;розыгрыше ценных призов</>

const GIFT_TITLE = (
  <>
    Оформите Халву и получите
    <br />
    <b> месяц&nbsp;подписки&nbsp;в&nbsp;подарок</b>
  </>
)

// Время для таймер(30мин)
const countDownTime = 1800000

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  const banner = useHybridBanner(
    Banner as any,
    { title: bannerTitle, desc: bannerDescription, orderNum: "2" },
    { orderNum: "2", variantStyle: "decemberInstallment36months" },
    { orderNum: "2", isDynamicText: true },
    data.allDynamicBanner
  )

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  const [longForm, setLongForm] = useState(false)

  useEffect(() => {
    if ((globalThis as any).KameleoonExperiment_271113 === 1024325) {
      setLongForm(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(globalThis as any).KameleoonExperiment_271113])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader showHint={false} hasCTA redLogoWithSovcombank />
      <div>{banner}</div>
      <FourAdvantagesPoints
        withTitle
        indent
        variant="newYearMedia"
        title="Преимущества с Халвой:"
        orderNum="3"
      />
      <FormPKW
        progressBar
        dataLayerName={longForm ? "longPersonalForm" : "shortPersonalForm"}
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        longForm={longForm}
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="4"
        onlyPKWPage
        isGreenApprove
      />
      <InstallmentAndDeposit orderNum="5" />
      <NewCalculator additionalEventInDataLayer orderNum="6" />
      <Partners
        title="Более 260&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="7"
      />
      <MoreBenefitsRedisign
        additionalEventInDataLayer
        orderNum="8"
        hasSubtitle
        bottomBlock={false}
      />
      <BottomCta variant="pkwRedesign" title={GIFT_TITLE} BgImg={RefinSeoBgImg} orderNum="9" />
      <Reviews additionalEventInDataLayer orderNum="10" />
      <Questions questionList={QUESTIONS_FOR_HALVA_PKW} orderNum="11" />
      <NewFooter ligal={ligal} orderNum="12" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/halva-i-vklad/media/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
